import { cloneElement } from "react";
import { useScrollTrigger } from "@mui/material";

export const ElevationScroll = ({ disabled, children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  return cloneElement(children, {
    elevation: trigger && !disabled ? 4 : 0,
  });
};