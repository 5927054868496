import { EventType, PublicClientApplication } from "@azure/msal-browser";

const apiClientId = process.env.REACT_APP_API_CLIENT_ID || "3ba775ef-bb5a-4efd-9235-bd1aef209ed0";
const webClientId = process.env.REACT_APP_WEB_CLIENT_ID || "153502ae-700b-4486-8ccc-45aec1e00423";
const b2cSubDomain = process.env.REACT_APP_B2C_DOMAIN || "waihapilot";

const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_SignUpSignIn"
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${b2cSubDomain}.b2clogin.com/${b2cSubDomain}.onmicrosoft.com/B2C_1_SignUpSignIn`,
    }
  },
  authorityDomain: `${b2cSubDomain}.b2clogin.com`
}

const b2cScopes = [`https://${b2cSubDomain}.onmicrosoft.com/${apiClientId}/user_impersonation`];

const msalConfig = {
  auth: {
    clientId: webClientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain]
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false
  },
  system: {
    iframeHashTimeout: 15000
  }
};

const loginRequest = {
  redirectUri: window.location.origin,
  scopes: ["openid", "offline_access", ...b2cScopes]
};

const pca = new PublicClientApplication(msalConfig);
if (!pca.getActiveAccount() && pca.getAllAccounts().length > 0) {
  pca.setActiveAccount(pca.getAllAccounts()[0]);
}

pca.enableAccountStorageEvents();
pca.addEventCallback((event) => {
  if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.SSO_SILENT_SUCCESS) && event.payload.account) {
    const account = event.payload.account;
    pca.setActiveAccount(account);
  }
});

export const getPcaInstance = () => pca;
export const getLoginRequest = () => loginRequest;