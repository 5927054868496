import React, { useContext } from "react";
import { Navigate, Route } from "react-router-dom";
import { useFeatures } from "flagged";
import { ProfileContext } from "../contexts/profile";
import { CenteredLoading } from "../components/loading";
import { Nav } from "../components/nav";

const PrivateRoute = ({ admin, children, flagName }) => {
  const profileContext = useContext(ProfileContext);
  const features = useFeatures();
  const featureEnabled = flagName ? features && features[flagName] : true;
  return profileContext.isReady
    ? (featureEnabled && (!admin || profileContext.profile.isAdmin) ? children : <Navigate to="/" />)
    : <CenteredLoading />
};

export const makePrivate = (path, element, admin, flagName, config) => (
  <Route path={path} element={
    <PrivateRoute admin={admin} flagName={flagName}>
      <Nav {...config}>
        {element}
      </Nav>
    </PrivateRoute>
  } />
);