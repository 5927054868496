import axios from "axios";
import { Environment, Network, RecordSource, Store } from "relay-runtime";
import axiosRetry, { isNetworkError } from "axios-retry";
import { getLoginRequest, getPcaInstance } from "./Auth";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const canRetry = error => isNetworkError(error)
  || (error.config.method === "post" && error.response?.status === 503);
axiosRetry(axios, { retries: 10, retryCondition: canRetry, retryDelay: axiosRetry.exponentialDelay });

const pca = getPcaInstance();

async function fetchRelay(params, variables) {
  let accessToken = null;
  try {
    const silent = await pca.acquireTokenSilent(getLoginRequest());
    accessToken = silent.accessToken;
  } catch (error) {
    // TODO: pass error back into React
    window.location.reload();
    return;
  }

  const response = await axios.post(
    `${apiBaseUrl}/graphql`,
    {
      query: params.text,
      variables,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      }
    });

  return response.data;
}

export default new Environment({
  network: Network.create(fetchRelay),
  store: new Store(new RecordSource()),
});