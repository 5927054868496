import React from "react";
import { TableCell } from "@mui/material";
import { LinkWrap } from "../linkWrap";

export const LinkCell = ({ link, children }) => (
  <TableCell>
    <LinkWrap to={link}>
      {children}
    </LinkWrap>
  </TableCell>
);

LinkCell.defaultProps = {
  link: "/"
};