import { parseDate, parseDateWithZone } from "./date";

export const dateFormatString = "D/M/YY";
export const dateShortFormatString = "D/M";
export const dateEditFormatString = "DD/MM/YY";
export const dateTimeFormatString = "D/M/YY HH:mm";
export const dateTimeEditFormatString = "DD/MM/YY HH:mm"
export const dateForFileNameFormatString = "D-M-YY";
const datePlotlyFormat = "YYYY-MM-DD";

export const formatDate = date => parseDateWithZone(date).format(dateFormatString);
export const formatDateShort = date => parseDateWithZone(date).format(dateShortFormatString);
export const formatDatePlotly = date => parseDateWithZone(date).format(datePlotlyFormat);
export const formatDateTime = dateTime => parseDateWithZone(dateTime).format(dateTimeFormatString);
export const formatDateTimeLocal = dateTime => parseDate(dateTime).format(dateTimeFormatString);
export const formatDateForFileName = date => parseDateWithZone(date).format(dateForFileNameFormatString);

export const formatVolume = (val, excludeUnits) => {
  val = val || 0;
  return (<>{val.toFixed(2)}{!excludeUnits && <> k.m<sup>3</sup></>}</>);
};

export const formatCurrency = (val, dp) => {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: dp ? dp : 2
  });

  val = val || 0;
  return (<>{currencyFormatter.format(val)}</>);
};