import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormGroup, TextField } from "@mui/material";
import "moment-timezone";

export const WaterUserDialog = ({ open, target, onCancel, onSave }) => {
  const [ lossFactor, setLossFactor ] = useState(null);
  const [ lossFactorError, setLossFactorError ] = useState(false);
  const [ maxOfftake, setMaxOfftake ] = useState(null);
  const [ maxOfftakeError, setMaxOfftakeError ] = useState(false);

  const handleLossFactorChange = e => {
    const lf = parseFloat(e.target.value);
    const ok = !isNaN(lf) && lf > 0;
    setLossFactor(lf);
    setLossFactorError(ok ? false : "Loss factor must be greater than 0.");
  };

  const handleMaxOfftakeChange = e => {
    const mo = parseFloat(e.target.value);
    const ok = !isNaN(mo) && mo > 0;
    setMaxOfftake(mo);
    setMaxOfftakeError(ok ? false : "Max offtake must be greater than 0.");
  };

  useEffect(() => {
    setLossFactor(target && target.lossFactor ? target.lossFactor : 0);
    setMaxOfftake(target && target.maxOfftake ? target.maxOfftake : 0);
  }, [ target ]);

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Edit</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Update the water user's configuration.
        </DialogContentText>
        <FormGroup>
          <TextField margin="dense" id="lossFactor" label="Loss factor" type="number" fullWidth variant="standard"
            value={lossFactor} onChange={handleLossFactorChange}error={!!lossFactorError} helperText={lossFactorError} />
          <TextField margin="dense" id="maxOfftake" label="Max offtake" type="number" fullWidth variant="standard"
            value={maxOfftake} onChange={handleMaxOfftakeChange} error={!!maxOfftakeError} helperText={maxOfftakeError} />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => onSave({ lossFactor, maxOfftake })} disabled={!!lossFactorError || !!maxOfftakeError}>Edit</Button>
      </DialogActions>
    </Dialog>
  )
};