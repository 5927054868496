import React, { useEffect, useMemo } from "react";
import { Alert, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import moment from "moment";
import { formatVolume, formatDateTime } from "../../../../common/utils/format";
import { chain } from "../../../../common/utils/math";

const restrictions = [
  {
    target: 'Farm 20',
    blocks: [
      'Farms 17, 18 & 19'
    ]
  }
];

export const PlanTable = ({ clearing, onPlanChanged }) => {
  const plan = useMemo(() => {
    if (clearing?.state !== 'PENDING') {
      return clearing?.plan ? JSON.parse(clearing?.plan) : [];
    }

    const wdpEnd = moment.parseZone(clearing.waterDeliveryPeriod.end);

    const items = [];
    while (items.length !== clearing?.allocations.length) {
      for (const a of clearing?.allocations) {
        const existing = items.find(x => x.name === a.waterUserExtractionPoint.name);
        if (existing) {
          continue;
        }

        let start = moment.parseZone(clearing.waterDeliveryPeriod.start);

        const r = restrictions.find(x => x.target === a.waterUserExtractionPoint.name);
        if (r) {
          const m = items.filter(x => r.blocks.includes(x.name));
          if (m.length !== r.blocks.length)
          {
            continue;
          }

          start = items.reduce((acc, x) => {
            const e = moment.parseZone(x.end);
            return e.isAfter(acc) ? e : acc;
          }, start);
        }

        const duration = chain(a.volume)
          .multiply(1000)
          .divide(3600) // sec/hr
          .divide(a.waterUserExtractionPoint.maxOfftake)
          .done();

        const end = start.clone().add(duration, 'hours');
        const isValid = wdpEnd.isSameOrAfter(end);
        items.push({
          name: a.waterUserExtractionPoint.name,
          publicId: a.waterUserExtractionPoint.publicId,
          start: start.format(),
          end: end.format(),
          duration,
          isValid,
          maxOfftake: a.waterUserExtractionPoint.maxOfftake,
          volume: a.volume
        })
      }
    }

    return items;
  }, [clearing]);

  useEffect(() => onPlanChanged && onPlanChanged(plan), [ onPlanChanged, plan ]);

  return (
    <>
      {plan?.some(x => !x.IsValid) && <Alert sx={{ mb: 1 }} severity="warning">A valid plan cannot be determined.</Alert>}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Water User ID</TableCell>
              <TableCell>Water User Name</TableCell>
              <TableCell>Max Offtake</TableCell>
              <TableCell>Volume</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plan?.map(x => (
              <TableRow key={x.publicId}>
                <TableCell>{x.publicId}</TableCell>
                <TableCell>{x.name}</TableCell>
                <TableCell>{x.maxOfftake.toFixed(2)} m<sup>3</sup>/sec</TableCell>
                <TableCell>{formatVolume(x.volume)}</TableCell>
                <TableCell>{x.duration.toFixed(2)} hrs</TableCell>
                <TableCell>{formatDateTime(x.start)}</TableCell>
                <TableCell>{formatDateTime(x.end)}</TableCell>
                <TableCell>{!x.isValid && <WarningAmberIcon />}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};