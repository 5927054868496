import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Query } from "./Query";

export const HomeHeader = ({ onExecute }) => (
  <>
    <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
      <Link underline="hover" color="text.primary">
        <Typography variant="body2">Overview</Typography>
      </Link>
    </Breadcrumbs>
    <Typography sx={{ mb: 2 }} variant="h1" component="h2">Requests</Typography>
    <Query onExecute={onExecute} />
  </>
);