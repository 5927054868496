/**
 * @generated SignedSource<<ee2a947bef5a615e51729d39c9cc09e3>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type ExchangeClearingState = "APPROVED" | "CANCELED" | "PENDING" | "REJECTED" | "%future added value";
export type clearingClearingQuery$variables = {|
  catchmentId: any,
  id: any,
|};
export type clearingClearingQuery$data = {|
  +exchangeClearings: ?$ReadOnlyArray<?{|
    +allocations: ?$ReadOnlyArray<?{|
      +id: ?string,
      +volume: any,
      +waterUserExtractionPoint: ?{|
        +id: ?string,
        +maxOfftake: ?any,
        +name: ?string,
        +publicId: ?string,
      |},
    |}>,
    +comment: ?string,
    +id: ?string,
    +plan: ?string,
    +state: ExchangeClearingState,
    +waterDeliveryPeriod: ?{|
      +end: any,
      +id: ?string,
      +start: any,
    |},
  |}>,
|};
export type clearingClearingQuery = {|
  response: clearingClearingQuery$data,
  variables: clearingClearingQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "catchmentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order",
        "value": {
          "waterDeliveryPeriod": {
            "start": "ASC"
          }
        }
      },
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "eq",
                            "variableName": "catchmentId"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "catchment"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.0"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "eq",
                        "variableName": "id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.1"
              }
            ],
            "kind": "ListValue",
            "name": "and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "ExchangeClearing",
    "kind": "LinkedField",
    "name": "exchangeClearings",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "comment",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "plan",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Allocation",
        "kind": "LinkedField",
        "name": "allocations",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "volume",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WaterUserExtractionPoint",
            "kind": "LinkedField",
            "name": "waterUserExtractionPoint",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "publicId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxOfftake",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WaterDeliveryPeriod",
        "kind": "LinkedField",
        "name": "waterDeliveryPeriod",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "start",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "end",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "clearingClearingQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "clearingClearingQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8c747e92e03084c66a157e37f413148a",
    "id": null,
    "metadata": {},
    "name": "clearingClearingQuery",
    "operationKind": "query",
    "text": "query clearingClearingQuery(\n  $catchmentId: UUID!\n  $id: UUID!\n) {\n  exchangeClearings(where: {and: [{catchment: {id: {eq: $catchmentId}}}, {id: {eq: $id}}]}, order: {waterDeliveryPeriod: {start: ASC}}) {\n    id\n    state\n    comment\n    plan\n    allocations {\n      id\n      volume\n      waterUserExtractionPoint {\n        id\n        name\n        publicId\n        maxOfftake\n      }\n    }\n    waterDeliveryPeriod {\n      id\n      start\n      end\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "b66d1c73af03bcac025016080148c5a4";

module.exports = ((node/*: any*/)/*: Query<
  clearingClearingQuery$variables,
  clearingClearingQuery$data,
>*/);
