import React, { Suspense, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQueryLoader } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { CenteredLoading } from "../../../common/components/loading";
import { Clearing } from "./components/Clearing";
import { ProfileContext } from "../../../common/contexts/profile";

const ClearingQuery = graphql`
  query clearingClearingQuery($catchmentId: UUID!, $id: UUID!) {
    exchangeClearings(where: { and: [ { catchment: { id: { eq: $catchmentId } } }, { id: { eq: $id } } ] }, order: { waterDeliveryPeriod: { start: ASC } }) {
      id
      state
      comment
      plan
      allocations {
        id
        volume
        waterUserExtractionPoint {
          id
          name
          publicId
          maxOfftake
        }
      }
      waterDeliveryPeriod {
        id
        start
        end
      }
    }
  }
`;

export const ClearingPage = () => {
  const { id } = useParams();
  const [ clearingQueryRef, loadClearingQuery ] = useQueryLoader(ClearingQuery);
  const { catchmentId } = useContext(ProfileContext);
  useEffect(() => loadClearingQuery({ catchmentId, id }, { fetchPolicy: "network-only" }),
    [ catchmentId, loadClearingQuery, id ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {clearingQueryRef && <Clearing clearingQueryDef={ClearingQuery} clearingQueryRef={clearingQueryRef} />}
    </Suspense>
  );
};