import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { usePreloadedQuery } from "react-relay";
import { formatDate } from "../../../../common/utils/format";
import { LinkCell } from "../../../../common/components/linkCell";
import { roundStartDate, roundEndDate } from "../../../../common/utils/date";
import { cap } from "../../../../common/utils/strings";
import { chain } from "../../../../common/utils/math";
import { formatVolume } from "../../../../common/utils/format";

export const HomeResults = ({ resultsQueryDef, resultsQueryRef }) => {
  const results = usePreloadedQuery(resultsQueryDef, resultsQueryRef);
  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Delivery Period</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Total Volume</TableCell>
            {/* <TableCell>&nbsp;</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {(results.exchangeClearings || []).map(x => {
            const link = `/clearings/${x.id}`;
            return (
              <TableRow key={x.id}>
                <LinkCell link={link}>{`${formatDate(roundStartDate(x.waterDeliveryPeriod.start))} - ${formatDate(roundEndDate(x.waterDeliveryPeriod.end))}`}</LinkCell>
                <LinkCell link={link}>{cap(x.state)}</LinkCell>
                <LinkCell link={link}>{formatVolume(x.allocations.reduce((p, c) => p.add(c.volume), chain(0)).done())}</LinkCell>
                {/* <TableCell>
                  <IconButton size="small" onClick={() => {}} sx={{ mr: 1 }}>
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </TableCell> */}
              </TableRow>);
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};