import React, { createContext, useEffect, useMemo, useState } from "react";
import { InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { Grid, Link, Typography } from "@mui/material";
import { CenteredLoading } from "../components/loading";
import { getLoginRequest, getPcaInstance } from "../services/Auth";

export const InitializationContext = createContext();

export const InitializationProvider = ({ children }) => {
  const { error, login, result } = useMsalAuthentication(InteractionType.Silent, getLoginRequest());
  const { inProgress } = useMsal();

  const [ isAuthenticated, setIsAuthenticated ] = useState(false);
  const [ isInitialized, setIsInitialized ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState(null);
  const state = useMemo(() => ({ isAuthenticated, isInitialized }),
    [ isAuthenticated, isInitialized ]);

  useEffect(() => {
    if (inProgress !== "none") {
      return;
    }

    if (error) {
      if (error instanceof InteractionRequiredAuthError) {
        login(window.parent !== window ? InteractionType.Popup : InteractionType.Redirect);
      } else {
        setErrorMessage(`An error occurred during login: ${error}`);
      }
    }

    if (result) {
      setIsAuthenticated(true);
      setErrorMessage(null);
    }
  }, [ error, login, inProgress, result, setErrorMessage, setIsInitialized ]);

  return (
    <InitializationContext.Provider value={state}>
      {!isAuthenticated && !errorMessage && <CenteredLoading />}
      {!isAuthenticated && errorMessage &&
        <Grid container justifyContent="center" alignItems="center" direction="column" sx={{ height: "100vh" }}>
          <Grid item>
            <Typography variant="h1" sx={{ mb: 1 }}>Something went wrong</Typography>
            {error && <Typography variant="body1" sx={{ mb: 1 }}>{error.message ? error.message : JSON.stringify(error)}</Typography>}
            <Typography variant="body1">
              Click <Link href="" onClick={() => getPcaInstance().logoutRedirect({ postLogoutRedirectUri: "/" })}>here</Link> to log out.
            </Typography>
          </Grid>
        </Grid>}
      {isAuthenticated && !errorMessage && children}
    </InitializationContext.Provider>
  );
};