import React, { useEffect, useState } from "react";
import { usePreloadedQuery } from "react-relay";
import { Button, Grid, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material";

export const CatchmentSelector = ({ catchmentId, setCatchmentName, setCatchmentId, profileQueryDef, profileQueryRef, setNowOffset, setNowOffsetDisabled, setNowOverride, setNowOverrideDisabled, setTimeZone }) => {
  const profileQuery = usePreloadedQuery(profileQueryDef, profileQueryRef);
  const [ currentCatchment, setCurrentCatchment ] = useState("");
  const [ isReady, setIsReady ] = useState(false);

  useEffect(() => {
    const match = profileQuery.catchments.find(x => x.id === catchmentId);
    if (profileQuery.catchments && match) {
      setCatchmentId(catchmentId);
      setCatchmentName(match.name);
      setTimeZone(match.timeZoneLinux);
      setNowOffset(match.nowOffset);
      setNowOffsetDisabled(match.nowOffsetDisabled);
      setNowOverride(match.nowOverride);
      setNowOverrideDisabled(match.nowOverrideDisabled);
    } else {
      setIsReady(true);
    }
  }, [ catchmentId, profileQuery, setCatchmentName, setCatchmentId, setNowOffset, setNowOffsetDisabled, setNowOverride, setNowOverrideDisabled, setTimeZone ]);

  const handleSave = () => {
    const match = profileQuery.catchments.find(x => x.id === currentCatchment);
    setCatchmentId(currentCatchment);
    setCatchmentName(match.name);
    setTimeZone(match.timeZoneLinux);
    setNowOffset(match.nowOffset);
    setNowOffsetDisabled(match.nowOffsetDisabled);
    setNowOverride(match.nowOverride);
    setNowOverrideDisabled(match.nowOverrideDisabled);
  };

  return (
    <>
      {isReady && <Grid container justifyContent="center" alignItems="center" direction="column" sx={{ height: "100vh" }}>
        <Grid item>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h1" sx={{ mb: 3 }}>Select your catchment</Typography>
            <FormControl fullWidth>
              <InputLabel id="catchment-label">Catchment</InputLabel>
              <Select labelId="catchment-label" value={currentCatchment} label="Catchment" onChange={e => setCurrentCatchment(e.target.value)}>
                {profileQuery.catchments.map(x => (<MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>))}
              </Select>
              <Button variant="contained" disabled={!currentCatchment} sx={{ mt: 2 }} onClick={handleSave}>Continue</Button>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>}
    </>
  );
};