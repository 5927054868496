import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { availableStates } from "../../home/components/Query";
import { cap } from "../../../../common/utils/strings";

export const ClearingEditDialog = ({ open, exchangeClearing, onCancel, onSave }) => {
  const [ state, setState ] = useState("");
  const [ comment, setComment ] = useState("");

  useEffect(() => {
    setState(exchangeClearing.state || "");
    setComment(exchangeClearing.comment || "");
  }, [ exchangeClearing ]);

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Edit</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Configure the exchange clearing.
        </DialogContentText>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="state-select-label" variant="standard">State</InputLabel>
          <Select
            labelId="state-select-label"
            id="state-select"
            value={state}
            label="State"
            variant="standard"
            onChange={x => setState(x.target.value)}
          >
            {availableStates.map(s => <MenuItem key={s} value={s}>{cap(s)}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <TextField
            id="comments"
            label="Comments"
            multiline
            rows={3}
            variant="standard"
            value={comment}
            onChange={x => setComment(x.target.value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => onSave({ state, comment })}>Edit</Button>
      </DialogActions>
    </Dialog>
  )
};