import React, { useContext, useMemo, useState } from "react";
import { AppBar, Box, Divider, Drawer, IconButton, List, Menu, MenuItem, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { LinkWrap } from "../linkWrap";
import { ProfileContext } from "../../../common/contexts/profile";
import { NavLink } from "./components/navLink";
import { CenteredDiv } from "./components/centeredDiv";
import { ElevationScroll } from "./components/elevationScroll";
import { NavWelcome } from "./components/navWelcome";
import { dateFormatString } from "../../utils/format";
import TktpLogoLight from "./tktp-logo-light.png";
import TktpLogoDark from "./tktp-logo-dark.png";
import { preferences } from "../../services/Preferences";
import "./nav.scss";

const DRAWER_WIDTH = 240;

export const Nav = ({ title, generalLinks, adminLinks, miscLinks, children, disableElevation }) => {
  const { instance } = useMsal();
  const profileContext = useContext(ProfileContext);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [ mobileOpen, setMobileOpen ] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState(null);

  const handleMenu = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const handleSwitchCatchment = () => {
    preferences.setCatchmentId(null);
    profileContext.setCatchmentId(null);
  };
  const handleLogoutClick = () => instance.logoutRedirect({ postLogoutRedirectUri: "/" });

  const today = useMemo(() => profileContext.now().format(dateFormatString), [ profileContext ]);

  const drawer = close => (
    <>
      <Toolbar />
      {generalLinks && generalLinks.length > 0 && <List>
        {generalLinks.map(({ name, link, icon, className }) =>
          <NavLink key={link} name={name} link={link} icon={icon} className={className} onClick={close} />
        )}
      </List>}
      {adminLinks && adminLinks.length > 0 && profileContext.profile && profileContext.profile.isAdmin && <>
        <Divider />
        <List>
          {adminLinks.map(({ name, link, icon, className }) =>
            <NavLink key={link} name={name} link={link} icon={icon} className={className} onClick={close} />
          )}
        </List>
      </>}
      {miscLinks && miscLinks.length > 0 && <>
        <Divider />
        <List>
          {miscLinks.map(({ name, link, icon, className }) =>
            <NavLink key={link} name={name} link={link} icon={icon} className={className} onClick={close} />
          )}
        </List>
      </>}
    </>
  );

  return (
    <Box className="nav" sx={{ display: { xs: "block", sm: "flex" } }}>
      <ElevationScroll disabled={disableElevation}>
        <AppBar position="fixed" sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <IconButton className="drawer-toggle" size="large" edge="start" color="inherit" aria-label="menu" sx={{ display: { xs: "block", sm: "none" } }}
              onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
            <CenteredDiv className="header">
              <img className="logo" src={prefersDarkMode ? TktpLogoDark : TktpLogoLight} alt="logo" />
              <Typography variant="h6" noWrap component="div" className="name">
                {title}
              </Typography>
            </CenteredDiv>
            <div>
              <Box sx={{ display: { xs: "none", sm: "inline-block" }, verticalAlign: "middle" }}>
                {profileContext.profile && <>
                  <NavWelcome name={profileContext.profile.name} component="div" sx={{ mb: 0, mt: 0 }} />
                  <Typography variant="caption" sx={{ ml: 2, mr: 2, mt: "-2px" }} component="div">{`${profileContext.catchmentName} | ${today}`}</Typography>
                </>}
              </Box>
              <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true"
                onClick={handleMenu} color="inherit">
                <AccountCircle />
              </IconButton>
              <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{ vertical: "top", horizontal: "right" }} keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }} open={Boolean(anchorEl)} onClose={handleClose}>
                <Box sx={{ display: { xs: "block", sm: "none" } }}>
                  {profileContext.profile && <NavWelcome name={profileContext.profile.name} />}
                  <Divider sx={{ mb: 1, mt: 1 }} />
                </Box>
                <LinkWrap to="/profile">
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                </LinkWrap>
                <MenuItem onClick={handleSwitchCatchment}>Switch catchment</MenuItem>
                <Divider />
                <MenuItem onClick={handleLogoutClick}>Log out</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: DRAWER_WIDTH },
          }}
          className="nav-drawer"
        >
          {drawer(handleDrawerToggle)}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            flexShrink: 0,
            width: DRAWER_WIDTH,
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: DRAWER_WIDTH },
          }}
          open
          PaperProps={{ elevation: 0 }}
        >
          {drawer()}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};