import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { formatVolume } from "../../../../common/utils/format";

export const AllocationsTable = ({ allocations }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Water User ID</TableCell>
          <TableCell>Water User Name</TableCell>
          <TableCell>Volume</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(allocations || []).map(x => (
          <TableRow key={x.id}>
            <TableCell>{x.waterUserExtractionPoint.publicId}</TableCell>
            <TableCell>{x.waterUserExtractionPoint.name}</TableCell>
            <TableCell>{formatVolume(x.volume)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);