/**
 * @generated SignedSource<<8c9de12125b43dd47e7089db73062250>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type AppProfileQuery$variables = {||};
export type AppProfileQuery$data = {|
  +catchments: ?$ReadOnlyArray<?{|
    +id: ?string,
    +name: ?string,
    +nowOffset: ?any,
    +nowOffsetDisabled: boolean,
    +nowOverride: ?any,
    +nowOverrideDisabled: boolean,
    +timeZoneLinux: ?string,
  |}>,
  +me: ?{|
    +id: ?string,
    +name: ?string,
    +userIdentities: ?$ReadOnlyArray<?{|
      +provider: ?string,
      +upn: ?string,
    |}>,
    +userRoles: ?$ReadOnlyArray<?{|
      +role: ?{|
        +code: ?string,
        +level: ?string,
      |},
      +target: any,
    |}>,
  |},
|};
export type AppProfileQuery = {|
  response: AppProfileQuery$data,
  variables: AppProfileQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upn",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provider",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "target",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "level",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "order",
      "value": {
        "name": "ASC"
      }
    }
  ],
  "concreteType": "Catchment",
  "kind": "LinkedField",
  "name": "catchments",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeZoneLinux",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nowOffset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nowOffsetDisabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nowOverride",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nowOverrideDisabled",
      "storageKey": null
    }
  ],
  "storageKey": "catchments(order:{\"name\":\"ASC\"})"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserIdentity",
            "kind": "LinkedField",
            "name": "userIdentities",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserRole",
            "kind": "LinkedField",
            "name": "userRoles",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserIdentity",
            "kind": "LinkedField",
            "name": "userIdentities",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserRole",
            "kind": "LinkedField",
            "name": "userRoles",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ]
  },
  "params": {
    "cacheID": "ce1916dbd0cb451994688f4a8189abc0",
    "id": null,
    "metadata": {},
    "name": "AppProfileQuery",
    "operationKind": "query",
    "text": "query AppProfileQuery {\n  me {\n    id\n    name\n    userIdentities {\n      upn\n      provider\n      id\n    }\n    userRoles {\n      target\n      role {\n        code\n        level\n        id\n      }\n      id\n    }\n  }\n  catchments(order: {name: ASC}) {\n    id\n    name\n    timeZoneLinux\n    nowOffset\n    nowOffsetDisabled\n    nowOverride\n    nowOverrideDisabled\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "d66623c8c7093946b2d56618a7bd4970";

module.exports = ((node/*: any*/)/*: Query<
  AppProfileQuery$variables,
  AppProfileQuery$data,
>*/);
