import React, { useMemo } from "react";
import { Routes } from "react-router-dom";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MsalProvider } from "@azure/msal-react";
import { RelayEnvironmentProvider } from "react-relay";
import { reactPlugin } from "./services/AppInsights";
import { getPcaInstance } from "./services/Auth";
import RelayEnvironment from "./services/RelayEnvironment";
import { ErrorBoundary } from "./components/errorBoundary";
import { InitializationProvider } from "./contexts/initialization";
import { NotificationProvider } from "./contexts/notification";
import { ProfileProvider } from "./contexts/profile";
import { HelpProvider } from "./contexts/help";
import CssBaseline from "@mui/material/CssBaseline";

const darkBackgroundColor = '#0D1117';
const lightBackgroundColor = 'white';

export const Shell = ({ children, palette, profileQuery }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          ...palette,
          mode: prefersDarkMode ? "dark" : "light",
          background: {
            default: prefersDarkMode ? darkBackgroundColor : "rgb(247, 249, 252)"
          }
        },
        typography: {
          h1: {
            fontSize: "20px",
            fontWeight: 500
          },
          h2: {
            fontSize: "18px",
            fontWeight: 400
          },
          h3: {
            fontSize: "16px",
            fontWeight: 400
          }
        },
        components: {
          MuiAppBar: {
            styleOverrides: {
              colorPrimary: {
                backgroundColor: prefersDarkMode ? darkBackgroundColor : lightBackgroundColor
              },
              root: {
                backgroundImage: "unset",
                borderBottom: "1px solid #035671",
                color: prefersDarkMode ? undefined : "rgb(0, 0, 0, 0.87)"
              }
            }
          },
          MuiButton: {
            styleOverrides: {
              root: {
                textAlign: "left",
                textTransform: "none"
              }
            }
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: prefersDarkMode ? darkBackgroundColor : lightBackgroundColor
              }
            }
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundColor: prefersDarkMode ? "#12141A" : lightBackgroundColor
              }
            }
          },
          MuiTab: {
            styleOverrides: {
              root: {
                "&.MuiButtonBase-root": {
                  textTransform: "none"
                }
              }
            }
          },
        }
      }),
    [ palette, prefersDarkMode ]
  );

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <MsalProvider instance={getPcaInstance()}>
        <RelayEnvironmentProvider environment={RelayEnvironment}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <ErrorBoundary full>
                <div className="App">
                  <NotificationProvider>
                    <InitializationProvider>
                      <ProfileProvider query={profileQuery}>
                        <HelpProvider>
                          <Routes>
                            {children}
                          </Routes>
                        </HelpProvider>
                      </ProfileProvider>
                    </InitializationProvider>
                  </NotificationProvider>
                </div>
              </ErrorBoundary>
            </ThemeProvider>
          </LocalizationProvider>
        </RelayEnvironmentProvider>
      </MsalProvider>
    </AppInsightsContext.Provider>
  );
};
