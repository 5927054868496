/**
 * @generated SignedSource<<01ee6debd5c39aed504d5bdca44dde11>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExchangeClearingState = "APPROVED" | "CANCELED" | "PENDING" | "REJECTED" | "%future added value";
export type UpdateExchangeClearingInput = {|
  comment?: ?string,
  id: any,
  plan?: ?string,
  state: ExchangeClearingState,
|};
export type ClearingUpdateClearingMutation$variables = {|
  input: UpdateExchangeClearingInput,
|};
export type ClearingUpdateClearingMutation$data = {|
  +updateExchangeClearing: {|
    +exchangeClearing: ?{|
      +comment: ?string,
      +id: ?string,
      +plan: ?string,
      +state: ExchangeClearingState,
    |},
  |},
|};
export type ClearingUpdateClearingMutation = {|
  response: ClearingUpdateClearingMutation$data,
  variables: ClearingUpdateClearingMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateExchangeClearingPayload",
    "kind": "LinkedField",
    "name": "updateExchangeClearing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExchangeClearing",
        "kind": "LinkedField",
        "name": "exchangeClearing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "state",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "comment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "plan",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClearingUpdateClearingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClearingUpdateClearingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1e67364e6d0f4533d1bd4657d56a1e6e",
    "id": null,
    "metadata": {},
    "name": "ClearingUpdateClearingMutation",
    "operationKind": "mutation",
    "text": "mutation ClearingUpdateClearingMutation(\n  $input: UpdateExchangeClearingInput!\n) {\n  updateExchangeClearing(input: $input) {\n    exchangeClearing {\n      id\n      state\n      comment\n      plan\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "14fb768742a3a52da921692f0bb8e678";

module.exports = ((node/*: any*/)/*: Mutation<
  ClearingUpdateClearingMutation$variables,
  ClearingUpdateClearingMutation$data,
>*/);
