import React, { useContext, useEffect, useState } from "react";
import { Autocomplete, Button, Grid, Paper, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { dateEditFormatString } from "../../../../common/utils/format";
import { ProfileContext } from "../../../../common/contexts/profile";
import { cap } from "../../../../common/utils/strings";

export const availableStates = [
  "PENDING",
  "APPROVED",
  "REJECTED",
  "CANCELED"
];

export const Query = ({ onExecute }) => {
  const [ end, setEnd ] = useState(null);
  const [ start, setStart ] = useState(null);
  const [ states, setStates ] = useState([availableStates[0]]);
  const [ isFirstLoad, setIsFirstLoad ] = useState(true);
  const { now } = useContext(ProfileContext);

  useEffect(() => {
    if (!start && !end) {
      const defaultStart = now({ ignoreTz: true }).add(-7, "day");
      setStart(defaultStart);

      const defaultEnd = defaultStart.clone().add(14, "day");
      setEnd(defaultEnd);
    }
  }, [ end, now, start ]);

  useEffect(() => {
    if (isFirstLoad && start && end) {
      onExecute({ end, start, states });
      setIsFirstLoad(false);
    }
  }, [ end, isFirstLoad, onExecute, start, states ]);

  const handleSelectedWaterUsersChange = (e, v) => setStates(v);
  const handleQuery = () => onExecute({ end, start, states });

  return (
    <Grid container component={Paper}>
      <Grid container item sx={{ p: 2 }} spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <DatePicker
            renderInput={(props) => <TextField fullWidth variant="standard" margin="dense" {...props} />}
            label="Start"
            value={start}
            onChange={setStart}
            inputFormat={dateEditFormatString}
            mask="__/__/__" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DatePicker
            renderInput={(props) => <TextField fullWidth variant="standard" margin="dense" {...props} />}
            label="End"
            value={end}
            onChange={setEnd}
            inputFormat={dateEditFormatString}
            mask="__/__/__" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            multiple
            id="states-select"
            options={availableStates}
            getOptionLabel={option => cap(option)}
            renderInput={props => <TextField fullWidth variant="standard" margin="dense" label="States" {...props} />}
            value={states}
            onChange={handleSelectedWaterUsersChange} />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleQuery}>Search</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};