import React, { Suspense, useContext } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { HomeHeader } from "./components/HomeHeader";
import { HomeResults } from "./components/HomeResults";
import { CenteredLoading, ResultsLoading } from "../../../common/components/loading";
import { ProfileContext } from "../../../common/contexts/profile";

const HomePageResultsQuery = graphql`
  query homePageHomePageResultsQuery($catchmentId: UUID!, $start: DateTime!, $end: DateTime!, $states: [ExchangeClearingState!]) {
    exchangeClearings(where: { and: [ { catchment: { id: { eq: $catchmentId } } }, { waterDeliveryPeriod: { start: { lte: $end } } }, { waterDeliveryPeriod: { end: { gte: $start } } }, { state: { in: $states } } ] }) {
      id
      state
      comment
      allocations {
        id
        volume
      }
      waterDeliveryPeriod {
        id
        start
        end
      }
    }
  }
`;

export const HomePage = () => {
  const [ homePageResultsQueryRef, loadHomePageResultsQuery ] = useQueryLoader(HomePageResultsQuery);
  const { catchmentId } = useContext(ProfileContext);

  const handleExecute = ({ end, start, states }) =>
    loadHomePageResultsQuery({
      catchmentId,
      end: end.format(),
      start: start.format(),
      states
    }, { fetchPolicy: "network-only" });

  return (
    <Suspense fallback={<CenteredLoading />}>
      <HomeHeader onExecute={handleExecute} />
      <Suspense fallback={<ResultsLoading />}>
        {homePageResultsQueryRef && <HomeResults resultsQueryDef={HomePageResultsQuery} resultsQueryRef={homePageResultsQueryRef} />}
      </Suspense>
    </Suspense>
  );
};