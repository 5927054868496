import React, { Suspense, useContext, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { Configuration } from "./components/Configuration";
import { CenteredLoading } from "../../../common/components/loading";
import { ProfileContext } from "../../../common/contexts/profile";

const ConfigurationQuery = graphql`
  query configurationConfigurationQuery($catchmentId: UUID!) {
    catchments(where: { id: { eq: $catchmentId } }) {
      name
      nowOffset
      nowOverride
    }
  }
`;

export const ConfigurationPage = () => {
  const { catchmentId } = useContext(ProfileContext);
  const [ configurationQueryRef, loadConfigurationQuery ] = useQueryLoader(ConfigurationQuery);
  useEffect(() => loadConfigurationQuery({ catchmentId }), [ loadConfigurationQuery, catchmentId ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {configurationQueryRef && <Configuration configurationQueryDef={ConfigurationQuery}
        configurationQueryRef={configurationQueryRef} />}
    </Suspense>
  );
};