import React, { Suspense, createContext, useContext, useEffect, useMemo, useState } from "react";
import { usePreloadedQuery, useQueryLoader } from "react-relay";
import { FlagsProvider } from "flagged";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import "moment-timezone";
import { CenteredLoading } from "../components/loading";
import { preferences } from "../services/Preferences";
import { InitializationContext } from "./initialization";
import { CatchmentSelector } from "../components/catchmentSelector";
import { appInsights } from "../services/AppInsights";

export const ProfileContext = createContext();

const ProfileUpdater = ({ catchmentId, profileQueryDef, profileQueryRef, setProfile }) => {
  const profileQuery = usePreloadedQuery(profileQueryDef, profileQueryRef); 
  useEffect(() => {
    if (catchmentId && profileQuery) {
      const userRoles = profileQuery.me.userRoles.filter(x => x.target === catchmentId);
      const isAdmin = userRoles.some(x => x.role.code === "Catchments" && x.role.level === "W");

      appInsights.setAuthenticatedUserContext(profileQuery.me.id);
      setProfile({ name: profileQuery.me.name, userRoles: profileQuery.me.userRoles, isAdmin });
    }
  }, [ catchmentId, profileQuery, setProfile ]);

  return (<></>);
};

export const ProfileProvider = ({ children, query }) => {
  const { isAuthenticated } = useContext(InitializationContext);
  const [ searchParams ] = useSearchParams();

  const [ catchmentId, setCatchmentId ] = useState(null);
  const [ catchmentName, setCatchmentName ] = useState(null);
  const [ timeZone, setTimeZone ] = useState(null);
  const [ isReady, setIsReady ] = useState(false);
  const [ profile, setProfile ] = useState(null);
  const [ nowOffset, setNowOffset ] = useState(null);
  const [ nowOffsetDisabled, setNowOffsetDisabled ] = useState(false);
  const [ nowOverride, setNowOverride ] = useState(null);
  const [ nowOverrideDisabled, setNowOverrideDisabled ] = useState(false);
  const [ flags, setFlags ] = useState(preferences.getFlags());
  const state = useMemo(() => ({
    catchmentName,
    catchmentId,
    isReady,
    nowOffset,
    nowOffsetDisabled,
    nowOverride,
    nowOverrideDisabled,
    profile,
    setCatchmentName,
    setCatchmentId,
    setNowOffset,
    setNowOffsetDisabled,
    setNowOverride,
    setNowOverrideDisabled,
    timeZone,
    now: (opts) => {
      const { ignoreTz } = opts || {};

      let r = null;
      if (nowOverride) {
        r = moment.parseZone(nowOverride);
      } else {
        r = timeZone && !ignoreTz ? moment.tz(timeZone) : moment();
        if (nowOffset) {
          r = r.add(moment.duration(nowOffset));
        }
      }
      return r;
    }
  }), [ catchmentName, catchmentId, isReady, nowOffset, nowOffsetDisabled, nowOverride, nowOverrideDisabled, profile, setCatchmentName, setCatchmentId, setNowOffset, setNowOffsetDisabled, setNowOverride, setNowOverrideDisabled, timeZone ]);

  const [ profileQueryRef, loadProfileQuery ] = useQueryLoader(query);
  useEffect(() => {
    if (isAuthenticated) {
      loadProfileQuery();
    }
  }, [ loadProfileQuery, isAuthenticated ]);

  useEffect(() => {
    if (catchmentId) {
      preferences.setCatchmentId(catchmentId);
    }

    setIsReady(!!catchmentId && !!profile && !!timeZone);
  }, [ catchmentId, profile, setIsReady, timeZone ]);

  useEffect(() => {
    if (flags && searchParams) {
      const newFlags = { ...flags };
      let changed = false;
      for (const p of searchParams.entries()) {
        const [ k, v ] = p;
        const arr = k.split(".");
        if (arr && arr.length === 2 && arr[0] === "ff") {
          const val = v === "true" || v === "1";
          if (newFlags[arr[1]] !== val) {
            newFlags[arr[1]] = val;
            preferences.setFlag(arr[1], val);
            changed = true;
          }
        }
      }

      if (changed) {
        setFlags(newFlags);
      }
    }
  }, [ flags, searchParams, setFlags ]);

  return (
    <ProfileContext.Provider value={state}>
      <FlagsProvider features={flags}>
        {isAuthenticated && !profile &&
          <Suspense fallback={<></>}>
            {profileQueryRef && <ProfileUpdater profileQueryDef={query} profileQueryRef={profileQueryRef}
              setProfile={setProfile} catchmentId={catchmentId} />}
          </Suspense>}
        {isAuthenticated && !isReady &&
          <Suspense fallback={<CenteredLoading />}>
            {profileQueryRef && <CatchmentSelector profileQueryDef={query} profileQueryRef={profileQueryRef}
              catchmentId={preferences.getCatchmentId()} setCatchmentId={setCatchmentId} setTimeZone={setTimeZone}
              setNowOffset={setNowOffset} setNowOffsetDisabled={setNowOffsetDisabled} setNowOverride={setNowOverride}
              setNowOverrideDisabled={setNowOverrideDisabled} setCatchmentName={setCatchmentName} />}
          </Suspense>}
        {(!isAuthenticated || isReady) && children}
      </FlagsProvider>
    </ProfileContext.Provider>
  );
};