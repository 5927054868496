import React from "react";
import { useLocation } from "react-router-dom";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { LinkWrap } from "../../linkWrap";

export const NavLink = ({ icon, link, name, className, onClick }) => {
  const location = useLocation();
  return (
    <LinkWrap to={link} extendedClassName={className}>
      <ListItemButton selected={link === location.pathname} onClick={onClick}
        sx={{ borderRight: link === location.pathname ? 6 : 0, borderColor: "primary.main" }}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={name} />
      </ListItemButton>
    </LinkWrap>
  );
};