/**
 * @generated SignedSource<<463e95068c3dd29e49f494c78c5f9cac>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type ExchangeClearingState = "APPROVED" | "CANCELED" | "PENDING" | "REJECTED" | "%future added value";
export type homePageHomePageResultsQuery$variables = {|
  catchmentId: any,
  end: any,
  start: any,
  states?: ?$ReadOnlyArray<ExchangeClearingState>,
|};
export type homePageHomePageResultsQuery$data = {|
  +exchangeClearings: ?$ReadOnlyArray<?{|
    +allocations: ?$ReadOnlyArray<?{|
      +id: ?string,
      +volume: any,
    |}>,
    +comment: ?string,
    +id: ?string,
    +state: ExchangeClearingState,
    +waterDeliveryPeriod: ?{|
      +end: any,
      +id: ?string,
      +start: any,
    |},
  |}>,
|};
export type homePageHomePageResultsQuery = {|
  response: homePageHomePageResultsQuery$data,
  variables: homePageHomePageResultsQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "catchmentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "states"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "eq",
                            "variableName": "catchmentId"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "catchment"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.0"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "lte",
                            "variableName": "end"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "start"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "waterDeliveryPeriod"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.1"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "gte",
                            "variableName": "start"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "end"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "waterDeliveryPeriod"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.2"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "in",
                        "variableName": "states"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "state"
                  }
                ],
                "kind": "ObjectValue",
                "name": "and.3"
              }
            ],
            "kind": "ListValue",
            "name": "and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "ExchangeClearing",
    "kind": "LinkedField",
    "name": "exchangeClearings",
    "plural": true,
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "comment",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Allocation",
        "kind": "LinkedField",
        "name": "allocations",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "volume",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WaterDeliveryPeriod",
        "kind": "LinkedField",
        "name": "waterDeliveryPeriod",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "start",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "end",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "homePageHomePageResultsQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "homePageHomePageResultsQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "230f39d2dfbff4acc1551a1dfb39df92",
    "id": null,
    "metadata": {},
    "name": "homePageHomePageResultsQuery",
    "operationKind": "query",
    "text": "query homePageHomePageResultsQuery(\n  $catchmentId: UUID!\n  $start: DateTime!\n  $end: DateTime!\n  $states: [ExchangeClearingState!]\n) {\n  exchangeClearings(where: {and: [{catchment: {id: {eq: $catchmentId}}}, {waterDeliveryPeriod: {start: {lte: $end}}}, {waterDeliveryPeriod: {end: {gte: $start}}}, {state: {in: $states}}]}) {\n    id\n    state\n    comment\n    allocations {\n      id\n      volume\n    }\n    waterDeliveryPeriod {\n      id\n      start\n      end\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "c99aaf3dc60c3fffd3cd6ba9c5c44877";

module.exports = ((node/*: any*/)/*: Query<
  homePageHomePageResultsQuery$variables,
  homePageHomePageResultsQuery$data,
>*/);
