import React, { useContext, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { usePreloadedQuery, useMutation } from "react-relay";
import { Link as LinkReact } from "react-router-dom";
import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { NotificationContext } from "../../../../common/contexts/notification";
import { WaterUsersTable } from "./WaterUsersTable";
import { WaterUserDialog } from "./WaterUserDialog";

const UpdateWaterUserExtractionPointMutation = graphql`
  mutation WaterUsersUpdateWaterUserExtractionPointMutation($input: UpdateWaterUserExtractionPointInput!) {
    updateWaterUserExtractionPoint(input: $input) {
      waterUserExtractionPoint {
        id
        name
        publicId
        lossFactor
        maxOfftake
        modifiedByUser {
          id
          name
        }
      }
    }
  }
`;

export const WaterUsers = ({ waterUsersQueryDef, waterUsersQueryRef }) => {
  const waterUsersQuery = usePreloadedQuery(waterUsersQueryDef, waterUsersQueryRef);
  const [ commitUpdateWaterUserExtractionPoint ] = useMutation(UpdateWaterUserExtractionPointMutation);
  const { setSuccess, setError } = useContext(NotificationContext);
  const [ open, setOpen ] = useState(false);
  const [ target, setTarget ] = useState(null);

  const handleEdit = item => {
    setTarget(item);
    setOpen(true);
  };

  const handleSave = item => {
    setOpen(false);
    commitUpdateWaterUserExtractionPoint({
      variables: {
        input: {
          id: target.id,
          lossFactor: item.lossFactor,
          maxOfftake: item.maxOfftake
        }
      },
      onCompleted,
      onError
    });
  };

  const onCompleted = e => setSuccess("Water user updated successfully");
  const onError = e => setError("Water user failed to update", e);

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Administration</Typography>
        </Link>
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">Water Users</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">Water Users</Typography>
      <Grid container sx={{ mt: 2 }}>
        <WaterUsersTable waterUsers={waterUsersQuery.waterUserExtractionPoints} onEdit={handleEdit} />
      </Grid>
      <WaterUserDialog open={open} target={target} onSave={handleSave} onCancel={() => setOpen(false)} />
    </>
  );
};