import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { Link as LinkReact } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import "./subheader.scss";

export const Subheader = ({ name, link, action, linkName }) => {
  const ln = linkName ? linkName : "See more...";
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2, mt: 3 }} className="subheader">
      <Typography variant="h2">
        <ChevronRightIcon color="primary" sx={{ mb: -0.8, ml: -0.6, mt: -1 }} />
        {name}
      </Typography>
      {(link || action) && <Typography variant="body2">
        {link && !action && <Link component={LinkReact} to={link}>{ln}</Link>}
        {!link && action && <Link sx={{ cursor: "pointer" }} onClick={action}>{ln}</Link>}
      </Typography>}
    </Box>
  );
};