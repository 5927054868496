/**
 * @generated SignedSource<<5e83dbcdfd8eb213d43ccfd5e78f9114>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateWaterUserExtractionPointInput = {|
  id: any,
  lossFactor?: ?any,
  maxOfftake?: ?any,
|};
export type WaterUsersUpdateWaterUserExtractionPointMutation$variables = {|
  input: UpdateWaterUserExtractionPointInput,
|};
export type WaterUsersUpdateWaterUserExtractionPointMutation$data = {|
  +updateWaterUserExtractionPoint: {|
    +waterUserExtractionPoint: ?{|
      +id: ?string,
      +lossFactor: ?any,
      +maxOfftake: ?any,
      +modifiedByUser: ?{|
        +id: ?string,
        +name: ?string,
      |},
      +name: ?string,
      +publicId: ?string,
    |},
  |},
|};
export type WaterUsersUpdateWaterUserExtractionPointMutation = {|
  response: WaterUsersUpdateWaterUserExtractionPointMutation$data,
  variables: WaterUsersUpdateWaterUserExtractionPointMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateWaterUserExtractionPointPayload",
    "kind": "LinkedField",
    "name": "updateWaterUserExtractionPoint",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WaterUserExtractionPoint",
        "kind": "LinkedField",
        "name": "waterUserExtractionPoint",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publicId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lossFactor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxOfftake",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedByUser",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WaterUsersUpdateWaterUserExtractionPointMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WaterUsersUpdateWaterUserExtractionPointMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "c1c94e8dfc9d3f0bcc2a6f040a3d565e",
    "id": null,
    "metadata": {},
    "name": "WaterUsersUpdateWaterUserExtractionPointMutation",
    "operationKind": "mutation",
    "text": "mutation WaterUsersUpdateWaterUserExtractionPointMutation(\n  $input: UpdateWaterUserExtractionPointInput!\n) {\n  updateWaterUserExtractionPoint(input: $input) {\n    waterUserExtractionPoint {\n      id\n      name\n      publicId\n      lossFactor\n      maxOfftake\n      modifiedByUser {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "37e4332614903c095aec87635b49ea65";

module.exports = ((node/*: any*/)/*: Mutation<
  WaterUsersUpdateWaterUserExtractionPointMutation$variables,
  WaterUsersUpdateWaterUserExtractionPointMutation$data,
>*/);
