import React from "react";
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export const WaterUsersTable = ({ waterUsers, onEdit }) => (
  <Grid item xs={12}>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Loss Factor</TableCell>
            <TableCell>Max Offtake</TableCell>
            <TableCell>Last Updated By</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {waterUsers.map(x => (
            <TableRow key={x.id}>
              <TableCell>{x.publicId}</TableCell>
              <TableCell>{x.name}</TableCell>
              <TableCell>{x.lossFactor}</TableCell>
              <TableCell>{x.maxOfftake}{x.maxOfftake !== null && (<> m<sup>3</sup>/s</>)}</TableCell>
              <TableCell>{x.modifiedByUser?.name}</TableCell>
              <TableCell>
                <IconButton size="small" onClick={() => onEdit(x)} sx={{ mr: 1 }}>
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>);