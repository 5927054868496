import React, { Component } from "react";
import { Grid, Link, Typography } from "@mui/material";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { reactPlugin } from "../../services/AppInsights";
import { getPcaInstance } from "../../services/Auth";

import "./errorBoundary.scss";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, error });
    reactPlugin.trackException({ exception: error, severityLevel: SeverityLevel.Error }, { errorInfo });
  }

  render() {
    const { hasError, error } = this.state;
    const { full } = this.props;

    if (hasError) {
      return (
        <Grid className={`eb ${(full ? "full" : "standard")}`} container>
          <Grid item>
            <Typography variant="h1" sx={{ mb: 1 }}>Something went wrong</Typography>
            {error && <Typography variant="body1" sx={{ mb: 1 }}>{error.message ? error.message : JSON.stringify(error)}</Typography>}
            <Typography variant="body1">
              Click <Link href="" onClick={() => window.location.reload()}>here</Link> to reload
              or <Link href="" onClick={() => getPcaInstance().logoutRedirect({ postLogoutRedirectUri: "/" })}>here</Link> to
              log out.
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return this.props.children; 
  }
}

ErrorBoundary.defaultProps = {
  full: false
};