import React, { Suspense, useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useQueryLoader } from "react-relay";
import { Profile } from "./components/Profile";
import { CenteredLoading } from "../../../common/components/loading";

const ProfileQuery = graphql`
  query profileProfileQuery {
    me {
      name
      userIdentities {
        upn
        provider
      }
    }
  }
`;

export const ProfilePage = () => {
  const [ profileQueryRef, loadProfileQuery ] = useQueryLoader(ProfileQuery);
  useEffect(() => loadProfileQuery(), [ loadProfileQuery ]);

  return (
    <Suspense fallback={<CenteredLoading />}>
      {profileQueryRef && <Profile profileQueryDef={ProfileQuery} profileQueryRef={profileQueryRef} />}
    </Suspense>
  );
};