import React from "react";
import { Box, CircularProgress, Grid } from "@mui/material";

export const Loading = () => (
  <>
    <Grid item sx={{ mb: 1 }}>
      <CircularProgress />
    </Grid>
    <Grid item>
      <div>Loading...</div>
    </Grid>
  </>
);

export const CenteredLoading = () => (
  <Grid container justifyContent="center" alignItems="center" direction="column" sx={{ height: "100vh" }}>
    <Loading />
  </Grid>
);

export const ResultsLoading = () => (
  <Grid container alignItems="center" direction="column" sx={{ mt: 6 }}>
    <Loading />
  </Grid>
);

export const WizardLoading = () => (
  <Box sx={{ mt: 7, mb: 2, textAlign: "center" }}>
    <Loading />
  </Box>
);