import { create, all, add as _add, bignumber, divide as _divide, multiply as _multiply, number, subtract as _subtract } from "mathjs";

export const math = create(all, {
  number: "BigNumber"
});

export const b = n => bignumber(n);
export const n = b => number(b);

export const add = (x, y) => n(_add(b(x), b(y)));
export const subtract = (x, y) => n(_subtract(b(x), b(y)));
export const multiply = (x, y) => n(_multiply(b(x), b(y)));
export const divide = (x, y) => n(_divide(b(x), b(y)));

class SimpleChainMath {
  constructor(init) {
    this.current = init;
  }

  add = x => new SimpleChainMath(this.current.add(b(x)));

  subtract = x => new SimpleChainMath(this.current.subtract(b(x)));

  multiply = x => new SimpleChainMath(this.current.multiply(b(x)));

  divide = x => new SimpleChainMath(this.current.divide(b(x)));

  done = () => n(this.current.done());
}

export const chain = init => new SimpleChainMath(math.chain(b(init)));