export const getErrorMessage = error => {
  if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0
    && error.response.data.errors[0].extensions && error.response.data.errors[0].extensions.message) {
      return error.response.data.errors[0].extensions.message;
  }

  if (error && error.message) {
    return error.message;
  }

  return "unknown error";
}