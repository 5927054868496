import React, { useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { HomePage } from "./pages/home";
import { ClearingPage } from "./pages/clearing";
import { ConfigurationPage } from "./pages/configuration";
import { WaterUsersPage } from "./pages/waterUsers";
import { ProfilePage } from "./pages/profile";
import { Shell } from "../common/shell";
import { makePrivate } from "../common/utils/app";

const ProfileQuery = graphql`
  query AppProfileQuery {
    me {
      id
      name
      userIdentities {
        upn
        provider
      }
      userRoles {
        target
        role {
          code
          level
        }
      }
    }
    catchments(order: { name: ASC }) {
      id
      name
      timeZoneLinux
      nowOffset
      nowOffsetDisabled
      nowOverride
      nowOverrideDisabled
    }
  }
`;

const App = () => {
  const generalLinks = [
    { name: "Overview", link: "/", icon: <HomeIcon /> }
  ];

  const adminLinks = [
    { name: "Water Users", link: "/admin/water-users", icon: <SupervisedUserCircleIcon /> },
    { name: "Configuration", link: "/admin/configuration", icon: <SettingsIcon /> }
  ];
  const miscLinks = [];

  const config = { title: "Operations", generalLinks, adminLinks, miscLinks };

  const palette = {
    primary: {
      light: '#4dabf5',
      main: '#2196f3',
      dark: '#1769aa',
      contrastText: '#fff',
    },
    secondary: {
      light: '#33c9dc',
      main: '#00bcd4',
      dark: '#008394',
      contrastText: '#000',
    },
    error: {
      light: '#b06256',
      main: '#9d3214',
      dark: '#711f03'
    }
  };

  useEffect(() => {
    document.title = "Operations";
  }, []);

  return (
    <Shell palette={palette} profileQuery={ProfileQuery}>
      {makePrivate("/", <HomePage />, false, null, config)}
      {makePrivate("/profile", <ProfilePage />, false, null, config)}
      {makePrivate("/clearings/:id", <ClearingPage />, false, null, config)}
      {makePrivate("/admin/water-users", <WaterUsersPage />, true, null, config)}
      {makePrivate("/admin/configuration", <ConfigurationPage />, true, null, config)}
    </Shell>
  );
};

export default App;
