import React, { createContext, useCallback, useMemo, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { getErrorMessage } from "../utils/errors";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showError, setShowError ] = useState(false);
  const [ successMessage, setSuccessMessage ] = useState();
  const [ errorMessage, setErrorMessage ] = useState();
  const appInsights = useAppInsightsContext();

  const setSuccess = useCallback(message => {
    setSuccessMessage(message);
    setShowSuccess(true);
  }, [ setSuccessMessage, setShowSuccess ]);
  const setError = useCallback((message, error) => {
    let errorMessage = message;
    if (error) {
      errorMessage += `: ${getErrorMessage(error)}`;
    }

    appInsights.trackException({ exception: error }, { errorMessage });
    setErrorMessage(errorMessage);
    setShowError(true);
  }, [ appInsights, setErrorMessage, setShowError ]);

  const state = useMemo(() => ({ setSuccess, setError }),
    [ setSuccess, setError ]);

  return (
    <NotificationContext.Provider value={state}>
      {children}
      <Snackbar open={showSuccess} autoHideDuration={2000} onClose={() => setShowSuccess(false)}>
        <Alert onClose={() => setShowSuccess(false)} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={showError} onClose={() => setShowError(false)}>
        <Alert onClose={() => setShowError(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};