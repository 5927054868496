/**
 * @generated SignedSource<<543820e1bafefb06b5af9fc233b43eca>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type profileProfileQuery$variables = {||};
export type profileProfileQuery$data = {|
  +me: ?{|
    +name: ?string,
    +userIdentities: ?$ReadOnlyArray<?{|
      +provider: ?string,
      +upn: ?string,
    |}>,
  |},
|};
export type profileProfileQuery = {|
  response: profileProfileQuery$data,
  variables: profileProfileQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upn",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provider",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "profileProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserIdentity",
            "kind": "LinkedField",
            "name": "userIdentities",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "profileProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserIdentity",
            "kind": "LinkedField",
            "name": "userIdentities",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c737297e45041f545a404438bd6cc545",
    "id": null,
    "metadata": {},
    "name": "profileProfileQuery",
    "operationKind": "query",
    "text": "query profileProfileQuery {\n  me {\n    name\n    userIdentities {\n      upn\n      provider\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "7ed2390be6cbaa72f6b71097eb4388f7";

module.exports = ((node/*: any*/)/*: Query<
  profileProfileQuery$variables,
  profileProfileQuery$data,
>*/);
