import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_AI_KEY || '00000000-0000-0000-0000-000000000000',
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin]
    }
});
appInsights.loadAppInsights();
appInsights.trackPageView();
export { reactPlugin, appInsights };