import React, { useContext, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { Breadcrumbs, Grid, IconButton, Link, Paper, Typography } from "@mui/material";
import { Link as LinkReact, Navigate } from "react-router-dom";
import { usePreloadedQuery } from "react-relay";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import EditIcon from "@mui/icons-material/Edit";
import { Subheader } from "../../../../common/components/subheader";
import { GridNode } from "../../../../common/components/gridNode";
import { formatDate, formatDateTime } from "../../../../common/utils/format";
import { cap } from "../../../../common/utils/strings";
import { AllocationsTable } from "./AllocationsTable";
import { PlanTable } from "./PlanTable";
import { ClearingEditDialog } from "./ClearingEditDialog";
import { NotificationContext } from "../../../../common/contexts/notification";

const UpdateClearingMutation = graphql`
  mutation ClearingUpdateClearingMutation($input: UpdateExchangeClearingInput!) {
    updateExchangeClearing(input: $input) {
      exchangeClearing {
        id
        state
        comment
        plan
      }
    }
  }
`;

export const Clearing = ({ clearingQueryDef, clearingQueryRef }) => {
  const clearingQuery = usePreloadedQuery(clearingQueryDef, clearingQueryRef);
  const [ commitUpdateMutation ] = useMutation(UpdateClearingMutation);
  const [ open, setOpen ] = useState(false);
  const [ plan, setPlan ] = useState(null);
  const { setSuccess, setError } = useContext(NotificationContext);
  const onCompleted = () => setSuccess("Exchange clearing updated successfully");
  const onError = e => setError("Exchange clearing failed to update", e);

  const clearing = clearingQuery.exchangeClearings && clearingQuery.exchangeClearings.length > 0 && clearingQuery.exchangeClearings[0];

  const onEdit = () => {
    setOpen(true);
  };

  const onSave = ({ state, comment }) => {
    setOpen(false);
    commitUpdateMutation({
      variables: { input: { id: clearing.id, state, comment, plan: JSON.stringify(plan) } },
      onCompleted,
      onError
    });
  };

  if (!clearing) {
    return (<Navigate to="/" />);
  }

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" fontSize="small" />} sx={{ mb: 2 }} aria-label="breadcrumb">
        <Link component={LinkReact} underline="hover" color="inherit" to="/">
          <Typography variant="body2">Clearings</Typography>
        </Link>
        <Link underline="hover" color="text.primary">
          <Typography variant="body2">{formatDate(clearing.waterDeliveryPeriod.start)} - {formatDate(clearing.waterDeliveryPeriod.end)}</Typography>
        </Link>
      </Breadcrumbs>
      <Typography variant="h1">Clearing</Typography>
      <Grid container className="clearing" sx={{ mb: 10 }}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Subheader name="Overview" action={onEdit} linkName={
            <IconButton size="small" onClick={onEdit} sx={{ mr: 1 }}>
              <EditIcon fontSize="inherit" />
            </IconButton>
          } />
        </Grid>
        <Grid container item xs={12} component={Paper} sx={{ p: 2, pt: 1 }} rowSpacing={1}>
          <GridNode name="Start" value={formatDateTime(clearing.waterDeliveryPeriod.start)} />
          <GridNode name="End" value={formatDateTime(clearing.waterDeliveryPeriod.end)} />
          <GridNode name={<></>} value={<></>} />
          <GridNode name="State" value={cap(clearing.state)} />
          <GridNode name="Comment" value={clearing.comment} />
        </Grid>
        <Grid item xs={12}>
          <Subheader name="Allocations" />
        </Grid>
        <Grid item xs={12}>
          <AllocationsTable allocations={clearing.allocations} />
        </Grid>
        <Grid item xs={12}>
          <Subheader name="Plan" />
        </Grid>
        <Grid item xs={12}>
          <PlanTable clearing={clearing} onPlanChanged={plan => setPlan(plan)} />
        </Grid>
      </Grid>
      <ClearingEditDialog open={open} exchangeClearing={clearing} onCancel={() => setOpen(false)} onSave={onSave} />
    </>
  );
};