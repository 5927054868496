import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import Joyride, { ACTIONS, LIFECYCLE } from "react-joyride";
import { useMediaQuery, useTheme } from "@mui/material";

export const HelpContext = createContext();

const getNextStep = (steps, index, isMobile) => {
  for (let i = index + 1; i < steps.length; i++) {
    const step = steps[i];
    if (!step.extensions || (!step.extensions.isMobile && !step.extensions.isDesktop) || (isMobile && step.extensions.isMobile) || (!isMobile && step.extensions.isDesktop)) {
      return [ step, i ];
    }
  }

  return [ null, null ];
};

const wait = (steps, index, helpers, isMobile) => setInterval(() => {
  const [ nextStep ] = getNextStep(steps, index, isMobile);
  if (nextStep) {
    const currentStep = steps[index];
    if (currentStep && currentStep.spotlightClicks) {
      const match = document.querySelectorAll(nextStep.target);
      if (match && match.length > 0
          && (match[0].offsetWidth > 0 || match[0].offsetHeight > 0)
          && window.getComputedStyle(match[0]).visibility !== "hidden") {
        const { next } = helpers;
        next();
      }
    }
  }
}, 50);

export const HelpProvider = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [ steps, setSteps ] = useState([]);
  const [ run, setRun ] = useState(false);
  const [ index, setIndex ] = useState(-1);
  const [ helpers, setHelpers ] = useState(null);

  useEffect(() => {
    if (steps && steps.length > 0) {
      if (!run && index === -1) {
        const [ nextStep, nextStepIndex ] = getNextStep(steps, -1, isMobile);
        setIndex(nextStep ? nextStepIndex : 0);
        setRun(true);
      }

      if (run) {
        const id = wait(steps, index, helpers, isMobile);
        return () => clearInterval(id);
      }
    } else {
      setRun(false);
    }
  }, [ helpers, isMobile, index, run, setRun, steps ]);

  const state = useMemo(() => ({ index, setIndex, steps, setSteps, run, setRun }),
    [ index, setIndex, steps, setSteps, run, setRun ]);

  const handleCallback = data => {
    if (!run || !steps) {
      return;
    }

    if (data.action === ACTIONS.CLOSE && data.lifecycle === LIFECYCLE.COMPLETE) {
      setRun(false);
    } else if (data.action === ACTIONS.NEXT && data.lifecycle === LIFECYCLE.COMPLETE) {
      const [ nextStep, nextStepIndex ] = getNextStep(steps, index, isMobile);
      if (nextStep) {
        setIndex(nextStepIndex);
      } else {
        setRun(false);
      }
    }
  };

  const handleGetHelpers = useCallback(h => setHelpers(h), [ setHelpers ]);

  return (
    <HelpContext.Provider value={state}>
      <>
        <Joyride steps={steps} run={run} styles={{ options: { primaryColor: theme.palette.primary.main, zIndex: 10000 } }}
          continuous={true} callback={handleCallback} getHelpers={handleGetHelpers} stepIndex={index} scrollOffset={128}
          locale={{
            back: "Back", close: "Close", last: "Done", next: "Next", skip: "Skip", open: "Open the dialog"
          }} />
        {children}
      </>
    </HelpContext.Provider>
  );
};