const KEY = "whp-prefs";

const DEFAULT_FLAGS = {
  debugCharts: false,
  debugSdChart: false
};

class PreferencesStorage
{
  constructor(storage) {
    this._prefs = null;
    this._storage = storage;
  }

  _load() {
    try {
      const current = this._storage.getItem(KEY);
      this._prefs = current ? JSON.parse(current) : {};
    } catch {
      this._prefs = {};
    }
  }

  _save() {
    this._storage.setItem(KEY, JSON.stringify(this._prefs));
  }

  get(key) {
    if (!this._prefs) {
      this._load();
    }

    return this._prefs[key];
  }

  set(key, val) {
    if (!this._prefs) {
      this._load();
    }

    this._prefs[key] = val;
    this._save();
  }
}

class Preferences
{
  constructor() {
    this._local = new PreferencesStorage(localStorage);
    this._session = new PreferencesStorage(sessionStorage);
  }

  getCatchmentId = () => this._local.get('catchmentId')

  setCatchmentId = id => this._local.set('catchmentId', id)

  getFlags = () => ({ ...DEFAULT_FLAGS, ...this._session.get('flags') })

  setFlags = flags => this._session.set('flags', flags)

  setFlag = (key, val) => {
    const current = this._session.get('flags') || {};
    current[key] = val;
    this.setFlags(current);
  }
}

export const preferences = new Preferences();